import { motion } from 'framer-motion';

const Header = () => {
  const logoUrl = new URL('../assets/logo.png', import.meta.url).href;

  const gradientTextStyle = {
    background: 'background linear-gradient(to right, #FFFFFF, #ffffff, #FFFFFF)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent'
  };

  const smoothScrollTo = (targetPosition: number, duration: number = 1000) => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime: number | null = null;

    function animation(currentTime: number) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    function easeInOutQuad(t: number, b: number, c: number, d: number) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = {
        'celebrities': 1,
        'wedding-collections': 1,
        'partners': 1,
        'contact': 1,
        'highlights': 1
      }[sectionId] || 1;

      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
      const scrollDuration = {
        'partners': 2000,
        'contact': 2000,
        'celebrities': 1000,
        'wedding-collections': 1000,
        'highlights': 1000
      }[sectionId] || 1000;

      smoothScrollTo(offsetPosition, scrollDuration);
    }
  };

  const scrollToContact = () => {
    const section = document.getElementById('contact');
    if (section) {
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 150;
      smoothScrollTo(offsetPosition, 2000);
    }
  };

  const navItems = [
    { label: 'Events', sectionId: 'wedding-collections' },
    { label: 'Celebrities', sectionId: 'celebrities' },
    { label: 'Highlights', sectionId: 'highlights' },
    { label: 'Partners', sectionId: 'partners' },
  ];

  return (
    <motion.header 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="absolute top-0 left-0 right-0 z-50 px-8 py-6"
    >
      <div className="max-w-[1920px] mx-auto flex items-center justify-between">
        {/* Logo - Made unclickable by removing the cursor-pointer class and onClick event */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <img 
            src={logoUrl} 
            alt="D.Chandirrasekar Decorations Private Limited"
            className="w-auto h-14 md:h-18"
            style={{
              filter: 'drop-shadow(0 0 10px rgba(0,0,0,0.2))'
            }}
          />
        </motion.div>

        {/* Navigation */}
        <nav className="flex items-center">
          <div className="flex items-center space-x-16 mr-16">
            {navItems.map((item) => (
              <motion.button 
                key={item.label}
                onClick={() => scrollToSection(item.sectionId)}
                className="font-medium hover:opacity-80 transition-opacity cursor-pointer relative group"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                style={gradientTextStyle}
              >
                {item.label}
                <span className="absolute left-0 bottom-0 w-full h-0.5 bg-gradient-to-r from-[#00C6FF] via-[#2DD4BF] to-[#6EE7B7] transform scale-x-0 transition-transform duration-300 origin-left group-hover:scale-x-100" />
              </motion.button>
            ))}
          </div>
          
          {/* Let's Talk Button with chat icon */}
          <motion.button 
            onClick={scrollToContact}
            className="relative px-6 py-2 rounded group overflow-hidden"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <div className="absolute inset-0 bg-gradient-to-r from-[#00C6FF] via-[#2DD4BF] to-[#6EE7B7] opacity-100 group-hover:opacity-80 transition-opacity" />
            <div className="relative flex items-center space-x-2 text-black">
              <span className="font-medium">Let's Talk</span>
              {/* Chat message icon */}
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" 
                />
              </svg>
            </div>
          </motion.button>
        </nav>
      </div>
    </motion.header>
  );
};

export default Header;